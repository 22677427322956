import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

//const wsUrl = "https://ws.scool-corner-web-api.pl";
const wsUrl = "https://testowe-ws.scool-corner-web-api.pl";
//const wsUrl = "http://localhost:3001";

export default function EditOrDelete() {
  const [listOfDonations, setListOfDonations] = useState([]);
  const [donationAmounts, setDonationAmounts] = useState({ totalRemainingAmount: 0 });
  const [rowModesModel, setRowModesModel] = useState({});

  let navigate = useNavigate();

  const fetchDonations = () => {
    axios.get(`${wsUrl}/donations/get100`, {
      headers: {
        accessToken: localStorage.getItem("accessToken")
      },
    }).then((response) => {
      setListOfDonations(response.data);
    });
  };

  const fetchDonationAmounts = () => {
    axios.get(`${wsUrl}/donations/amounts`).then((response) => {
      setDonationAmounts(response.data);
    });
  };

  useEffect(() => {
    fetchDonations();
    fetchDonationAmounts();
  }, []);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: 'edit' } });
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: 'view' } });
  };

  const handleDeleteClick = (id) => async () => {
    if (window.confirm("Czy chcesz usunąć tą wpłatę?") === true) {
      await axios.delete(`${wsUrl}/donations/id/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken")
        },
      });
      fetchDonations();
      fetchDonationAmounts();
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: 'view', ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    const updatedRows = listOfDonations.map((row) => (row.id === newRow.id ? newRow : row));
    setListOfDonations(updatedRows);
    await axios.put(`${wsUrl}/donations/id/${newRow.id}`, newRow, {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    });
    fetchDonations();
    fetchDonationAmounts();
    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    console.error('Error processing row update:', error);
  };

  const columns = [
    { field: 'name', headerName: 'Nazwa', width: 300, editable: true },
    { field: 'amount', headerName: 'Kwota wpłaty', type: 'number', width: 250, editable: true },
    { field: 'remainingAmount', headerName: 'Pozostałe saldo', type: 'number', width: 250, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        borderRadius: 2,
      }}
    >
      <h1> BANK: {donationAmounts.totalRemainingAmount} zł</h1>
      <h2>DO BANKU DORZUCILI SIĘ:</h2>
      <Box sx={{ height: 500, width: '100%', bgcolor: 'rgba(240, 240, 240, 0.7)'}}>
        <DataGrid
          rows={listOfDonations}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          componentsProps={{
            toolbar: {
              setRows: setListOfDonations,
              setRowModesModel: setRowModesModel,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
        />
      </Box>
    </Box>
  );
}
