import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

//const wsUrl = "https://ws.scool-corner-web-api.pl";
const wsUrl = "https://testowe-ws.scool-corner-web-api.pl";
//const wsUrl = "http://localhost:3001";

export default function EditProduct() {
  const [listOfProducts, setListOfProducts] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});

  const fetchProducts = () => {
    axios.get(`${wsUrl}/products/admin`, {
      headers: {
        accessToken: localStorage.getItem("accessToken")
      }
    }).then((response) => {
      setListOfProducts(response.data);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: 'edit' } });
  };

  const handleSaveClick = (id) => async () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: 'view' } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: 'view', ignoreModifications: true },
    });
  };

  const processRowUpdate = async (newRow) => {
    const updatedRows = listOfProducts.map((row) => (row.id === newRow.id ? newRow : row));
    setListOfProducts(updatedRows);
    await axios.put(`${wsUrl}/products/id/${newRow.id}`, newRow, {
      headers: {
        accessToken: localStorage.getItem("accessToken")
      },
    });
    fetchProducts();
    return newRow;
  };

  const handleProcessRowUpdateError = (error) => {
    console.error('Error processing row update:', error);
  };

  const columns = [
    { field: 'name', headerName: 'Nazwa', width: 300, editable: false },
    { field: 'price', headerName: 'Cena', type: 'number', width: 250, editable: true },
    { field: 'priceAfterDiscount', headerName: 'Cena dla klienta', type: 'number', width: 250, editable: true },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === 'edit';
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              onClick={handleCancelClick(id)}
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={handleEditClick(id)}
          />
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 4,
        borderRadius: 2,
      }}
    >
      <h2>PRODUKTY:</h2>
      <Box sx={{ height: 500, width: '100%', bgcolor: 'rgba(240, 240, 240, 0.7)'}}>
        <DataGrid
          rows={listOfProducts}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={setRowModesModel}
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          componentsProps={{
            toolbar: {
              setRows: setListOfProducts,
              setRowModesModel: setRowModesModel,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10]}
        />
      </Box>
    </Box>
  );
}
